import React from 'react';
import styled from 'styled-components/native';
import { ActivityIndicator } from './SimpleComponents';

const IndicatorView = styled.View`
  flex-direction: row;
  justify-content: center;
`;

type SizeType = number | 'small' | 'large';

interface SpinnerProps {
  size?: SizeType;
}

const SpinnerBlack = ({ size = 'large' }: SpinnerProps) => (
  <IndicatorView>
    <ActivityIndicator size={size} color="#000000" />
  </IndicatorView>
);

export default SpinnerBlack;
