import React from 'react';
import styled from 'styled-components/native';
import WaffleText from './WaffleText';
import styles, { DarkText } from '../Constants/Style';
import {
  IsSmallScreen,
  IsMidScreen,
  AdaptiveContentWidth,
} from '../Helper/DeviceHelper';

const leftPadding = IsSmallScreen() ? 25 : 50;
const height = IsMidScreen() ? 175 : 260;

const WrapperView = styled.View`
  height: ${height}px;
  width: 100%;
  z-index: -1;
  position: absolute;
`;

// Container for header text
const Inner = styled.View`
  padding-left: ${leftPadding}px;
  height: ${height}px;
  flex: 1;
  justify-content: center;
`;

const Holder = styled.View``;

const TitleText = styled(WaffleText)`
  width: 300px;
  color: ${DarkText};
  font-size: 32px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 35px;
  padding-bottom: 3px;
`;

const SubTitleText = styled(WaffleText)`
  width: ${AdaptiveContentWidth()}px;
  color: ${DarkText};
  font-size: 18px;
  line-height: 19px;
`;

export default (props: any) => (
  <WrapperView style={[styles.fullWidth]}>
    <Inner>
      <Holder>
        <TitleText>{props.title}</TitleText>
      </Holder>
      {props.subtitle && (
        <Holder>
          <SubTitleText>{props.subtitle}</SubTitleText>
        </Holder>
      )}
    </Inner>
  </WrapperView>
);
