import { useQuery } from '@apollo/client';
import React from 'react';

import LiveChat from 'react-livechat';
import { GET_LOCAL_USER } from '../GraphQL/Waffle/Queries';

const WaffleLiveChat = () => {
  const { data, loading, error } = useQuery(GET_LOCAL_USER);

  if (!data || loading || error) {
    return <></>;
  } else {
    const {
      user: { id, firstName, lastName, email },
    } = data;
    const visitor = {
      name: `${firstName} ${lastName}`,
      email,
      sessionFields: {
        waffleId: id,
      },
    };

    return <LiveChat license={12013368} visitor={visitor} />;
  }
};

export default WaffleLiveChat;
