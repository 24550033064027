import React from 'react';
import styled, { css } from 'styled-components/native';
import { Button, Platform, ScrollViewProps, View } from 'react-native';
import WaffleText, { WaffleTextFamily } from './WaffleText';
import { IsSmallScreen } from '../Helper/DeviceHelper';
import styles, {
  ScreenHeight,
  WaffleLightBlue,
  WaffleOrange,
} from '../Constants/Style';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { HomeScreenName, SettingsScreenName } from '../../screen-config';
import { useNavigation } from '@react-navigation/native';
import { Resizable } from 'react-native-web-hooks';

/*
 * A collection of simple components that are used in multiple places
 */
export const PlainView = styled.View``;
export const PlainScrollView = styled.ScrollView``;
export const TextLink = styled(WaffleText)`
  color: #151a62;
  text-decoration-line: underline;
`;

export const OrangeTextLink = styled(WaffleText)`
  color: ${WaffleOrange};
  text-decoration-line: underline;
  ${Platform.OS === 'web' &&
  css`
    cursor: pointer;
  `};
`;

export const TextLightLink = styled(WaffleText)`
  color: ${WaffleLightBlue};
  text-decoration-line: underline;
  ${Platform.OS === 'web' &&
  css`
    cursor: pointer;
  `};
`;

// A row of data.  Meant to be a wrapper for other items.  Perhaps specify height.
export const DataRow = styled.View``;

const spacing = (n) => n * 8;

export const Spacer = ({
  x = undefined,
  y = undefined,
  basis = undefined,
  styleProps = {},
  ...restProps
}) => (
  <View
    style={{
      width: x ? spacing(x) : undefined,
      height: y ? spacing(y) : undefined,
      flexBasis: basis ? spacing(basis) : undefined,
      flexGrow: 0,
      flexShrink: 0,
      ...styleProps,
    }}
    {...restProps}
  />
);

// A view to wrap content.  On a native platform,
// it will be just a plain view.  On web, it's
// a view with a maximum width of 960px, centered.
export const ContentView = ({ children, style }: any) => (
  <PlainView
    style={[
      Platform.OS === 'web'
        ? {
            marginLeft: 'auto',
            marginRight: 'auto',
            width: 720,
          }
        : {},
      style,
    ]}>
    {children}
  </PlainView>
);

export const CenterLinkText = styled(TextLink)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;

export const ScrollView = ({ children, ...rest }: any) => (
  <PlainScrollView
    showsVerticalScrollIndicator={false}
    showsHorizontalScrollIndicator={false}
    {...rest}>
    {children}
  </PlainScrollView>
);

// Default props for scroll views
export const DefaultScrollViewProps: ScrollViewProps = {
  scrollsToTop: false,
  showsVerticalScrollIndicator: false,
  showsHorizontalScrollIndicator: false,
  bounces: false,
  overScrollMode: 'never',
};

export const DefaultScrollView = ({ children, ...rest }: any) => (
  <PlainScrollView {...DefaultScrollViewProps} {...rest}>
    {children}
  </PlainScrollView>
);

export const ScrollViewMaybe = ({ children, ...props }: any) => {
  if (IsSmallScreen()) {
    return <DefaultScrollView {...props}>{children}</DefaultScrollView>;
  } else {
    return <>{children}</>;
  }
};

export const Image = styled.Image``;
export const Text = styled(WaffleText)``;
export const ActivityIndicator = styled.ActivityIndicator``;

export const FullScreenContent = (props: any) => (
  <Resizable>
    {(layout) => (
      <PlainView
        style={[
          // { height: layout.height },
          styles.fullHeight,
          styles.fullWidth,
          styles.whiteBackground,
          styles.invisibleBorder,
          props.style,
        ]}>
        {props.children}
      </PlainView>
    )}
  </Resizable>
);

export const MobileWebWrapper = (props: any) => (
  <PlainView style={[styles.fullWidth, styles.whiteBackground, props.style]}>
    {props.children}
  </PlainView>
);

export const TouchableOpacity = styled.TouchableOpacity``;

// @ts-ignore
export const Shadow = ({ style, children }: any) => (
  <PlainView
    style={[
      {
        shadowColor: 'rgba(108,62,12,0.23)',
        shadowOffset: { width: 0, height: 2 },
        // shadowBlur: 30,
        // shadowSpread: 0,
        shadowOpacity: 1,
        shadowRadius: 10,
        borderRadius: 28, // added for React Native Web
        elevation: 5,
      },
      style,
    ]}>
    {children}
  </PlainView>
);

/*
 * A full-screen transparent overlay for modals
 */
export const ModalBackground = styled.View`
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  flex: 1;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const TextInput = styled.TextInput`
  height: 44px;
  width: 100%;
  border-radius: 8px;
  background-color: #ffffff;
  border: 1px solid #dedede;
  color: #4a4a4a;
  font-family: ${WaffleTextFamily};
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  padding-left: 14px;
`;

export const ThickPadding = styled.View`
  height: 53px;
  width: 100%;
  background-color: rgba(246, 246, 246, 0.9);
  position: absolute;
  z-index: -1;
`;

export const NavigationBack = ({ routeName }: { routeName: string }) => {
  const navigation = useNavigation();

  return (
    <Icon
      name={'chevron-left'}
      size={40}
      onPress={() => navigation.navigate(routeName)}
    />
  );
};
export const NavigationGoBackButton = () => {
  const navigation = useNavigation();

  return (
    <Button onPress={() => navigation.goBack()} title="Done" color="#000" />
  );
};

export const NavigationGoHomeButton = () => {
  const navigation = useNavigation();
  return (
    <Button
      onPress={() => navigation.navigate(HomeScreenName)}
      title="Done"
      color="#000"
    />
  );
};

export const NavigationGoSettingsButton = () => {
  const navigation = useNavigation();
  return (
    <Button
      onPress={() => navigation.navigate(SettingsScreenName)}
      title="Done"
      color="#000"
    />
  );
};
