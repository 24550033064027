import React, { useState } from 'react';
// import { Badge } from 'react-native-elements';

import { PlainView } from './SimpleComponents';
import WaffleLiveChat from './WaffleLiveChat';
import { isMobileWeb } from '../Helper/DeviceHelper';

// todo : persist chat counts between screens!
export const WaffleChatButton = ({ disabled, iconColor, size = 34 }: any) => {
  const [chats, setChats] = useState(0);

  if (isMobileWeb()) {
    // For now, we're disabling live chat on mobile web.
    return <PlainView style={{ width: size, height: size }}></PlainView>;
  }

  return (
    <PlainView style={{ width: size, height: size }}>
      <WaffleLiveChat
        size={size}
        disabled={disabled}
        onEvent={(ev) => setChats((chats) => chats + 1)}
        onChatClose={() => setChats(0)}
        iconColor={iconColor}
      />
    </PlainView>
  );
};

/*
      {chats > 0 && (
        <Badge
          status="success"
          value={chats}
          containerStyle={{ position: 'absolute', top: -7, right: -7 }}
        />
      )}

 */
