import React, { useEffect, useRef, useState } from 'react';
import {
  Animated,
  TouchableWithoutFeedback,
  Easing,
  LayoutChangeEvent,
  ViewStyle,
  Platform,
} from 'react-native';
import { WaffleGrey, WaffleOrange, White } from '../Constants/Style';
import Icon from 'react-native-vector-icons/FontAwesome';

const dynamicStyles = {
  container: (
    animation: Animated.Value,
    containerWidth: number
  ): ViewStyle => ({
    backgroundColor: animation.interpolate({
      inputRange: [0, 1],
      outputRange: [WaffleGrey, WaffleOrange],
    }) as any,
    borderRadius: 25,
    height: 32,
    width: containerWidth,
    padding: 2,
  }),
};

const styling = {
  knob: (animation: Animated.Value, containerWidth): Animated.Animated => ({
    borderRadius: 28 / 2,
    width: 28,
    height: 28,
    backgroundColor: White,
    justifyContent: 'center',
    alignItems: 'center',
    transform: [
      {
        translateX: animation.interpolate({
          inputRange: [0, 1],
          outputRange: [0, containerWidth - 32],
        }),
      },
    ],
  }),
};

type KnobProps = {
  containerWidth: number;
  color: string;
  animation: Animated.Value;
};

function Knob({ containerWidth, animation, color }: KnobProps) {
  return (
    <Animated.View style={[styling.knob(animation, containerWidth)]}>
      <Icon color={color} name={'check'} size={20} />
    </Animated.View>
  );
}

type Props = {
  onPress: () => void;
  isEnabled: boolean;
  isDisabled?: boolean;
};

export default function Toggle({
  isDisabled = false,
  onPress,
  isEnabled,
}: Props) {
  const animation = useRef(new Animated.Value(isEnabled ? 1 : 0)).current;
  const [containerWidth, setContainerWidth] = useState(91);
  const [isEnabledLocal, setIsEnabledLocal] = useState(isEnabled);

  useEffect(() => {
    setIsEnabledLocal(isEnabled);
    animation.setValue(isEnabled ? 1 : 0);
  }, [isEnabled]);

  const onLayout = (layoutChangeEvent: LayoutChangeEvent) => {
    setContainerWidth(layoutChangeEvent.nativeEvent.layout.width);
  };

  return (
    <TouchableWithoutFeedback
      disabled={isDisabled}
      onPress={async () => {
        setIsEnabledLocal(!isEnabled);
        Animated.timing(animation, {
          duration: 300,
          toValue: +!(isEnabledLocal ? 1 : 0),
          useNativeDriver: Platform.OS !== 'ios',
          easing: Easing.bezier(0.4, 0, 0.2, 1),
        }).start();
        await onPress();
      }}>
      <Animated.View
        style={dynamicStyles.container(animation, containerWidth)}
        onLayout={onLayout}>
        <Knob
          animation={animation}
          containerWidth={containerWidth}
          color={isEnabledLocal ? WaffleOrange : 'white'}
        />
      </Animated.View>
    </TouchableWithoutFeedback>
  );
}
