import React from 'react';
import styled from 'styled-components/native';

import { Image, PlainView, Spacer } from './SimpleComponents';
import style, {
  WaffleDarkBlue,
  WaffleLightBlue,
  White,
  DarkText,
} from '../Constants/Style';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import WaffleText from './WaffleText';
import {
  IsSmallScreen,
  AdaptiveBiggerContentWidth,
  isNativeOrMobileWeb,
} from '../Helper/DeviceHelper';
import { OrangeHeaderImage } from './HeaderNavTitle.web';
import { ContentCenterScroll, ContentUnified } from './ContentShared';
import { Platform } from 'react-native';

const adaptiveFontSize = IsSmallScreen() ? 25 : 37;

export interface StepMessageProps {
  text: string;
  onPress: () => Promise<void>;
}

const TextNative = styled(WaffleText)`
  width: ${AdaptiveBiggerContentWidth()}px;
  color: ${White};
  font-size: ${adaptiveFontSize}px;
  font-weight: 700;
  line-height: ${adaptiveFontSize + 5}px;
  text-align: center;
`;

const TextWeb = styled(WaffleText)`
  width: 600px;
  color: ${DarkText};
  font-size: 42px;
  font-weight: 400;
  line-height: 46px;
  letter-spacing: -0.5px;
  text-align: center;
`;

const StepWebMessage = ({ text, onPress }: StepMessageProps) => (
  <ContentUnified
    image={OrangeHeaderImage}
    ChildrenWrapper={ContentCenterScroll}>
    <Image
      source={require('../../assets/images/girl-lookout-no-shadow-1.webp')}
      resizeMode={'contain'}
      style={{ height: 475, width: 650 }}
    />

    <Spacer y={1} />

    <TextWeb>{text}</TextWeb>

    <Spacer y={5} />

    <WaffleButtonAsync
      onPress={onPress}
      name={'Next'}
      style={{ backgroundColor: WaffleLightBlue }}
    />
  </ContentUnified>
);

const StepNativeOrMobileWebMessage = ({ text, onPress }: StepMessageProps) => (
  <PlainView
    style={[
      { backgroundColor: WaffleDarkBlue },
      style.verticalCenter,
      style.fullWidth,
      style.fullHeight,
    ]}>
    <Image
      source={require('../../assets/images/juggleMoreSpecificSmall.png')}
      resizeMode={'contain'}
      style={{ height: 271, width: 300 }}
    />

    <Spacer y={3} />

    <TextNative
      style={[Platform.OS === 'web' && { fontSize: 25, lineHeight: 29 }]}>
      {text}
    </TextNative>

    <Spacer y={5} />

    <WaffleButtonAsync
      onPress={onPress}
      name={'Next'}
      style={{ backgroundColor: WaffleLightBlue }}
    />
  </PlainView>
);

const StepMessage = ({ text, onPress }: StepMessageProps) => {
  if (isNativeOrMobileWeb()) {
    return <StepNativeOrMobileWebMessage text={text} onPress={onPress} />;
  } else {
    return <StepWebMessage text={text} onPress={onPress} />;
  }
};

export { StepMessage };
