import React, { useState } from 'react';

import { PlainView, Spacer } from '../SimpleComponents';
import { HorizontalPacked } from '../SimpleLayouts';
import WaffleText from '../WaffleText';
import { WebViewPopupLink } from '../WebViewPopupLink';
import { Markup } from '../Markup';
import { CheckSquare } from '../CheckSquare';
import {
  AdaptiveBiggerContentWidth,
  AdaptiveContentWidth,
  isFullWeb,
} from '../../Helper/DeviceHelper';

export const PetAgreeDisclosures = ({ consent, setConsent }: any) => {
  const [viewLink, setViewLink] = useState(false);

  return (
    <PlainView style={{ marginLeft: 0, marginBottom: 10, marginTop: 10 }}>
      <HorizontalPacked>
        <CheckSquare
          testID="pet-disclosure-checkbox"
          value={consent}
          disabled={!viewLink}
          onValueChanged={(newValue) => setConsent(newValue)}
        />
        <WaffleText
          style={{
            marginLeft: 10,
            width: AdaptiveBiggerContentWidth(),
            fontWeight: '700',
            fontSize: 16,
          }}>
          Please read the&nbsp;
          <WebViewPopupLink
            text={'Terms and Conditions'}
            uri={
              'https://content.trywaffle.com/hubfs/checkout-terms-and-conditions_final.pdf'
            }
            title={'Pet Terms & Conditions'}
            onDismiss={async () => await setViewLink(true)}
          />{' '}
          before checking the box.
        </WaffleText>
      </HorizontalPacked>
    </PlainView>
  );
};

export const CyberAgreeDisclosures = ({
  disclosure,
  consent,
  setConsent,
}: any) => {
  const [viewLink, setViewLink] = useState(false);

  return (
    <PlainView
      testID="cyberDisclosure"
      style={{ marginLeft: 0, marginBottom: 10, marginTop: 10 }}>
      <HorizontalPacked
        style={{ alignItems: 'flex-start', justifyContent: 'flex-start' }}>
        <CheckSquare
          testID="cyber-disclosure-checkbox"
          value={consent}
          disabled={!viewLink}
          onValueChanged={(newValue) => setConsent(newValue)}
        />
        <WaffleText
          style={{
            marginLeft: 10,
            width: isFullWeb() ? 500 : AdaptiveContentWidth(),
            fontWeight: '700',
            fontSize: 16,
          }}>
          Please accept the{' '}
          <WebViewPopupLink
            text={'Electronic Communications Disclosure'}
            uri={
              'https://blinkinsured.com/legal/electronic-communication-disclosure-v1.html'
            }
            title={'Electronic Communications Disclosure'}
            onDismiss={async () => await setViewLink(true)}
          />{' '}
          by reading the agreement and checking the box.
        </WaffleText>
      </HorizontalPacked>

      {!!disclosure && (
        <>
          <Spacer y={2.5} />

          <Markup
            style={{
              paragraph: {
                marginTop: 0,
                marginBottom: 0,
              },
              body: {
                fontSize: 14,
              },
            }}>
            {disclosure}
          </Markup>
        </>
      )}
    </PlainView>
  );
};
