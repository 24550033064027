import React, { useEffect, useState } from 'react';
import { Switch } from 'react-native';
import { WaffleOrange } from '../Constants/Style';

type WaffleSwitchProps = {
  disabled?: boolean;
  value: boolean;
  setValue: (boolean) => Promise<void>;
};

export type LocalSwitchProps = {
  localValue: boolean;
  setLocalValue: (v: boolean) => Promise<void>;
};

// export const LocalSwitchState = ({ disabled, value, setValue }) => {
//   const [localValue, setLocalValue] = useState(value);
//
//   useEffect(() => setLocalValue(value), [value]);
//
// }

export const WaffleSwitch = ({
  disabled,
  value,
  setValue,
}: WaffleSwitchProps) => {
  // This is very distasteful, IMO.  We maintain local state that mirrors the external value, but we do it since
  // setValue() takes a while, and for whatever reason, the toggle will flip back while waiting for it to complete.
  // This effectively causes a "soft update" of state.
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => setLocalValue(value), [value]);

  return (
    <Switch
      disabled={disabled}
      value={localValue}
      trackColor={{
        false: '#E9E9EA',
        true: WaffleOrange,
      }}
      onValueChange={async (v) => {
        if (v !== value) {
          await setLocalValue(v);
          await setValue(v);
        }
      }}
    />
  );
};
