import React, { useState } from 'react';
import { ValidateUserAddress_validateAddress_suggestions } from '../../../operation-result-types';
import Popup from './Popup';
import WaffleText, { LabelUpperText, PopupTitle } from './WaffleText';
import { Spacer } from './SimpleComponents';
import { HorizontalSpread } from './SimpleLayouts';
import { WaffleButtonAsync } from './WaffleButtonAsync';
import {
  WaffleDarkBlue,
  WaffleMediumGrey,
  WaffleOrange,
  White,
} from '../Constants/Style';
import styled from 'styled-components/native';
import { TouchableWithoutFeedback } from 'react-native';
import { Logger } from '../Helper/Logger';

type Address = {
  address?: string;
  address2?: string;
  city?: string;
  state?: string;
  zip?: string;
};

type Props = {
  address: Address;
  suggestion: ValidateUserAddress_validateAddress_suggestions;
  onDismiss: () => void;
  onPick: (a: Address) => Promise<void>;
};

const AddressBox = styled.View`
  padding: 20px;
  border-width: 1px;
  border-color: ${WaffleMediumGrey};
  border-radius: 5px;
`;

export const SuggestedAddressPopup = ({
  address,
  suggestion,
  onDismiss,
  onPick,
}: Props) => {
  const [selected, setSelected] = useState(1);

  if (!address || !suggestion) {
    return null;
  }

  const onPress = async () => {
    if (selected === 0) {
      await onPick(address);
    } else {
      await onPick(suggestion);
    }
  };

  return (
    <Popup
      visible={!!suggestion}
      onDismiss={onDismiss}
      viewStyle={{ minWidth: 335 }}>
      <Spacer y={2.5} />

      <PopupTitle>Address Verification</PopupTitle>

      <Spacer y={2.5} />

      <LabelUpperText>Original Address</LabelUpperText>

      <TouchableWithoutFeedback onPress={() => setSelected(0)}>
        <AddressBox
          style={{
            borderColor: selected === 0 ? WaffleOrange : WaffleMediumGrey,
          }}>
          <WaffleText>{address.address}</WaffleText>
          {!!address.address2 && <WaffleText>{address.address2}</WaffleText>}
          <WaffleText>
            {address.city}, {address.state} {address.zip}
          </WaffleText>
        </AddressBox>
      </TouchableWithoutFeedback>

      <Spacer y={2.5} />

      <LabelUpperText>Suggested Address</LabelUpperText>

      <TouchableWithoutFeedback onPress={() => setSelected(1)}>
        <AddressBox
          style={{
            borderColor: selected === 1 ? WaffleOrange : WaffleMediumGrey,
          }}>
          <WaffleText>{suggestion.address}</WaffleText>
          {suggestion.address2 && (
            <WaffleText>{suggestion.address2}</WaffleText>
          )}
          <WaffleText>
            {suggestion.city}, {suggestion.state} {suggestion.zip}
          </WaffleText>
        </AddressBox>
      </TouchableWithoutFeedback>

      <Spacer y={2.5} />

      <HorizontalSpread>
        <WaffleButtonAsync
          onPress={onPress}
          name={'Use this address'}
          style={{ width: '48%', borderRadius: 5 }}
        />

        <Spacer x={1} />

        <WaffleButtonAsync
          onPress={async () => onDismiss()}
          name={'Cancel'}
          style={{
            width: '48%',
            borderRadius: 5,
            backgroundColor: White,
            borderWidth: 1,
            borderColor: WaffleDarkBlue,
          }}
          textStyle={{ color: WaffleDarkBlue }}
        />
      </HorizontalSpread>
    </Popup>
  );
};
