import { useApolloClient, useQuery } from '@apollo/client';
import React from 'react';
import styled, { css } from 'styled-components/native';
import { Icon, ListItem } from 'react-native-elements';

import { Image, PlainView, Spacer, TouchableOpacity } from './SimpleComponents';
import styles, {
  IsFullSize,
  MenuWidth,
  WaffleOrange,
  White,
} from '../Constants/Style';
import WaffleText, { WaffleTextFamily } from './WaffleTextSatoshi';
import {
  Packed,
  VerticalCenter,
  VerticalLeft,
  VerticalSpread,
} from './SimpleLayouts';
import CustomLinking from '../Helper/CustomLinking';
import { GET_LOCAL_USER } from '../GraphQL/Waffle/Queries';
import { Platform, ScaledSize } from 'react-native';
import { CompleteUserState } from '../Helper/UserHelpers';
import { LocalUser } from '../../../operation-result-types';
import { useNavigation, useRoute } from '@react-navigation/native';
import {
  ChubbCompleteScreenName,
  ChubbPaymentScreenName,
  ChubFinalizingScreenName,
  ClaimChatScreenName,
  ConnectToCFScreenName,
  CyberQuoteScreenName,
  HomeScreenName,
  LifeQuoteScreenName,
  PaymentInfoScreenName,
  PetQuoteScreenName,
  ProductChatScreenName,
  ProductChooserScreenName,
  ReferralScreenName,
  RentersQuoteScreenName,
  SettingsScreenName,
  SuggestedCoverageScreenName,
  YourPolicyScreenName,
} from '../../screen-config';
import { useAuthContext } from '../Screens/AuthProvider';

// const width = 240;

const textColor = '#373737';

const whiteLineBottom = {
  borderBottomWidth: 3,
  borderBottomColor: White,
};

const Text = styled(WaffleText.Medium)`
  font-size: 14px;
  line-height: 18px;
  color: ${textColor};
`;

const TextLink = styled(Text)`
  text-decoration-line: underline;
  ${Platform.OS === 'web' &&
  css`
    cursor: pointer;
  `};
`;

const LeftNavSpacer = ({ layout }: any) => (
  <Spacer x={IsFullSize(layout) ? 3 : 1.25} />
);

interface MenuItemProps {
  text: string;
  iconName: string;
  containerStyle?: Record<string, unknown> | null;
  isSelected?: boolean;
  layout: ScaledSize;
  disabled?: boolean;
  onPress?: () => void;
}

const MenuItem = ({
  text,
  iconName,
  disabled = false,
  onPress,
  containerStyle = {},
  isSelected = false,
  layout,
}: MenuItemProps) => (
  <ListItem
    disabled={disabled}
    onPress={onPress}
    disabledStyle={{ opacity: 0.25 }}
    containerStyle={[containerStyle]}>
    <ListItem.Content
      style={{
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        minWidth: 42,
      }}>
      <LeftNavSpacer layout={layout} />

      <Icon
        tvParallaxProperties={{}}
        name={iconName}
        type={'material-community'}
        color={isSelected ? WaffleOrange : textColor}
        size={24}
      />

      {IsFullSize(layout) && (
        <>
          <Spacer x={2} />

          <ListItem.Title
            style={{
              fontFamily: isSelected
                ? WaffleTextFamily.Black
                : WaffleTextFamily.Medium,
              fontSize: 21,
              maxWidth: 100,
              color: isSelected ? WaffleOrange : textColor,
            }}>
            {text}
          </ListItem.Title>
        </>
      )}
    </ListItem.Content>
  </ListItem>
);

const NavBox = ({ layout, height, children }: any) => (
  <VerticalLeft style={[whiteLineBottom, { width: MenuWidth(layout), height }]}>
    {children}
  </VerticalLeft>
);

const PlainNavBox = ({ layout, height, children }: any) => (
  <Packed
    style={{
      width: MenuWidth(layout),
      height,
      // ...styles.blueBorder,
      // borderWidth: 4,
    }}>
    {children}
  </Packed>
);

const WaffleLogoButton = ({ disabled, layout }: any) => {
  if (IsFullSize(layout)) {
    return (
      <NavBox layout={layout} height={104}>
        <VerticalCenter style={{ width: '100%' }}>
          <TouchableOpacity disabled={disabled}>
            <Image
              source={require('../../assets/images/Waffle Rebrand 2022 - Full Logo.webp')}
              resizeMode={'contain'}
              style={{ width: 182, height: 78 }}
            />
          </TouchableOpacity>
        </VerticalCenter>
      </NavBox>
    );
  } else {
    return (
      <NavBox layout={layout} height={104}>
        <VerticalCenter style={{ width: '100%' }}>
          <Image
            source={require('../../assets/images/Waffle Rebrand 2022 - Icon Only.png')}
            resizeMode={'contain'}
            style={{ width: 39, height: 78 }}
          />
        </VerticalCenter>
      </NavBox>
    );
  }
};

const onPrivacy = () =>
  CustomLinking.openURL('https://www.trywaffle.com/privacy-policy', '_blank');

const onHelp = () =>
  CustomLinking.openURL(
    'https://www.trywaffle.com/help-center-overview',
    '_blank'
  );

const onTerms = () =>
  CustomLinking.openURL('https://www.trywaffle.com/terms-of-use', '_blank');

const SideBarFooter = ({ layout }: any) => {
  if (IsFullSize(layout)) {
    return (
      <VerticalCenter>
        <TextLink onPress={onTerms}>Terms of Service</TextLink>

        <Spacer y={3} />

        <TextLink onPress={onPrivacy}>Privacy Policy</TextLink>

        <Spacer y={4} />

        <Text>Waffle Labs Inc. 2021</Text>

        <Spacer y={2} />
      </VerticalCenter>
    );
  }

  return (
    <VerticalCenter>
      <MenuItem
        iconName={'clipboard-check-outline'}
        text={'Terms of use'}
        disabled={false}
        layout={layout}
        containerStyle={{ height: 18 }}
      />
      <Spacer y={1} />
      <MenuItem
        iconName={'shield-check-outline'}
        text={'Privacy Policy'}
        disabled={false}
        layout={layout}
        containerStyle={{ height: 18 }}
      />
      <Spacer y={1} />
      <MenuItem
        iconName={'copyright'}
        text={'Copyright'}
        disabled={false}
        layout={layout}
        containerStyle={{ height: 18 }}
      />
      <Spacer y={2} />
    </VerticalCenter>
  );
};

interface Props {
  layout?: ScaledSize;
}

const SideNav: React.FC<Props> = ({ layout }) => {
  const client = useApolloClient();
  const navigation = useNavigation();
  const route = useRoute();
  const { isGuest } = useAuthContext();
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const isCompleted = user?.userState === 'COMPLETE';
  const productScreens = new Set([
    ProductChooserScreenName,
    ProductChatScreenName,
    CyberQuoteScreenName,
    PetQuoteScreenName,
    RentersQuoteScreenName,
    SuggestedCoverageScreenName,
    YourPolicyScreenName,
    PaymentInfoScreenName,
    ChubbPaymentScreenName,
    ChubFinalizingScreenName,
    ChubbCompleteScreenName,
    ConnectToCFScreenName,
    LifeQuoteScreenName,
  ]);

  const goHome = async () => {
    // If the user isn't complete, we update the user to a completed status
    // before going to home.
    if (!isCompleted) {
      await CompleteUserState(client);
    }
    navigation.navigate(HomeScreenName);
  };

  return (
    <VerticalSpread
      style={[
        styles.fullHeight,
        {
          width: MenuWidth(layout),
          backgroundColor: White,
          shadowRadius: 32,
          shadowOffset: { width: 0, height: 4 },
          shadowColor: 'rgba(108,62,12,0.5)',
          shadowOpacity: 1,
          elevation: 5,
        },
      ]}>
      <PlainView>
        <WaffleLogoButton layout={layout} disabled={!isCompleted} />

        <PlainNavBox layout={layout} height={470}>
          <Spacer y={1} />
          <MenuItem
            iconName={'home'}
            text={'Home'}
            disabled={isGuest}
            onPress={goHome}
            layout={layout}
            isSelected={route?.name === HomeScreenName}
          />
          <MenuItem
            iconName={'account-circle'}
            text={'Account'}
            disabled={isGuest || !isCompleted}
            onPress={() => navigation.navigate(SettingsScreenName)}
            layout={layout}
            isSelected={route?.name === SettingsScreenName}
          />
          <MenuItem
            iconName={'magnify'}
            text={'Products'}
            onPress={() => {
              if (!isGuest && !productScreens.has(route.name)) {
                navigation.navigate(ProductChooserScreenName);
              }
            }}
            layout={layout}
            // or if in one of the adding screens
            isSelected={isGuest || productScreens.has(route?.name)}
          />
          <MenuItem
            iconName={'contacts'}
            text={'Referral'}
            disabled={isGuest || !isCompleted}
            onPress={() => navigation.navigate(ReferralScreenName)}
            layout={layout}
            isSelected={route?.name === ReferralScreenName}
          />
          <MenuItem
            iconName={'chat'}
            text={'Claims Center'}
            disabled={isGuest || !isCompleted}
            onPress={() => navigation.navigate(ClaimChatScreenName)}
            layout={layout}
            isSelected={route?.name === ClaimChatScreenName}
          />
          <MenuItem
            iconName={'help-circle-outline'}
            // iconName={'frequently-asked-questions'}
            text={'FAQ'}
            disabled={isGuest || !isCompleted}
            onPress={onHelp}
            layout={layout}
          />
        </PlainNavBox>
      </PlainView>

      <PlainView>
        <SideBarFooter layout={layout} />
      </PlainView>
    </VerticalSpread>
  );
};

export { SideNav };
