import styled from 'styled-components/native';

export class WaffleTextFamily {
  static readonly Black: string = 'Satoshi Black';
  static readonly Light: string = 'Satoshi Light';
  static readonly Medium: string = 'Satoshi Medium';
  static readonly Regular: string = 'Satoshi';
}

class WaffleText {
  static readonly Black = styled.Text`
    font-family: ${WaffleTextFamily.Black};
    color: #000000;
  `;

  static readonly Light = styled.Text`
    font-family: ${WaffleTextFamily.Light};
    color: #000000;
  `;

  static readonly Medium = styled.Text`
    font-family: ${WaffleTextFamily.Medium};
    color: #000000;
  `;

  static readonly Regular = styled.Text`
    font-family: ${WaffleTextFamily.Regular};
    color: #000000;
  `;
}

export default WaffleText;
