import React, { useState } from 'react';
import styled from 'styled-components/native';
import ButtonText from './ButtonText';
import { WaffleDarkGrey, WaffleOrange } from '../Constants/Style';

const TouchableHighlight = styled.TouchableHighlight`
  height: 55px;
  width: 269px;
  border-radius: 28px;
  justify-content: center;
`;

export const WaffleHighlightButton = (props: any) => {
  const [pressed, setPressed] = useState(false);
  const { name } = props;

  return (
    <TouchableHighlight
      activeOpacity={1}
      style={{
        ...(pressed
          ? { backgroundColor: WaffleOrange }
          : { backgroundColor: WaffleDarkGrey }),
        ...props.style,
      }}
      onHideUnderlay={() => setPressed(false)}
      onShowUnderlay={() => setPressed(true)}
      underlayColor={WaffleOrange}
      onPress={() => props.onPress()}>
      <ButtonText
        style={{
          ...(pressed ? { color: '#FFF' } : { color: '#070D59' }),
          ...props.textStyle,
        }}>
        {name}
      </ButtonText>
    </TouchableHighlight>
  );
};
