import React from 'react';
import styled from 'styled-components/native';

import WaffleText from './WaffleText';
import { TotalPolicyPrices } from '../Helper/PolicyHelper';
import { PoliciesProps } from '../../component-config';
import { LinePlaceholder } from './LinePlaceholder';
import { Spacer } from './SimpleComponents';

const TotalAmount = styled(WaffleText)`
  font-size: 48px;
  font-weight: 700;
  text-align: center;
`;

export const TotalBillablePolicies = ({ policies }: PoliciesProps) => {
  const price = TotalPolicyPrices(policies);

  if (price && parseInt(price) > 0) {
    return <TotalAmount>${price}</TotalAmount>;
  } else {
    const width = 200;

    return (
      <>
        <Spacer y={0.75} />
        <LinePlaceholder width={width} height={48} textHeight={35} />
      </>
    );
  }
};
