import React from 'react-native';
import styled from 'styled-components/native';

/*
 * justify-content: alignment along main axis
 * align-items:     alignment cross axis
 */
export const HorizontalSpread = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

// export const HorizontalEnd = styled.View`
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
// `;

export const HorizontalPacked = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const HorizontalEvenly = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

export const Horizontal = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const VerticalAround = styled.View`
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
`;

// export const VerticalBetween = styled.View`
//   flex-direction: column;
//   justify-content: space-around;
//   align-items: center;
// `;

export const VerticalSpread = styled.View`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

// todo : rename the below!  and some others in this class
export const VerticalPacked = styled.View`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const Packed = styled.View`
  flex-direction: column;
  justify-content: flex-start;
`;

export const VerticalCenter = styled.View`
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const VerticalLeft = styled.View`
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

// export const FlexBottom = styled.View`
//   flex-direction: column;
//   justify-content: flex-end;
// `;
