import React from 'react';
import styled from 'styled-components/native';
import WaffleText, { LabelUpperText, WaffleTextFamily } from './WaffleText';
import { WaffleTextInputProps } from '../../component-config';

const TextInput = styled.TextInput`
  height: 44px;
  border-radius: 8px;
  background-color: #fff;
  border: 1px solid #dedede;
  color: #4a4a4a;
  font-family: ${WaffleTextFamily};
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  padding-left: 14px;
`;

const View = styled.View`
  flex: 1;
`;
// width: 45%;
//flex-grow: 1;

// email:

// todo : refactor
const BaseText = styled(WaffleText)`
  height: 20px;
  font-size: 14px;
  line-height: 20px;
`;

const Text = styled(BaseText)`
  text-transform: uppercase;
  text-align: left;
`;

export const FooterText = styled(Text)`
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
`;

export const ErrorFooterText = styled(FooterText)`
  color: red;
  text-transform: none;
`;

const WaffleHalfTextInput = ({
  containerProps,
  containerStyle,
  label,
  placeholder,
  onChangeText,
  value,
  footerText,
  errorFooterText,
  onValidate,
  otherProps,
}: WaffleTextInputProps) => (
  <View {...containerProps} style={containerStyle}>
    {label && <LabelUpperText>{label}</LabelUpperText>}
    <TextInput
      placeholder={placeholder}
      placeholderTextColor={'#AFAFAF'}
      {...otherProps}
      onChangeText={onChangeText}
      onEndEditing={(ev) => onValidate && onValidate(ev.nativeEvent.text)}
      value={value}
    />
    {!!footerText && <FooterText>{footerText}</FooterText>}
    {!!errorFooterText && <ErrorFooterText>{errorFooterText}</ErrorFooterText>}
  </View>
);

export default WaffleHalfTextInput;
