import React, { useState } from 'react';
import styled from 'styled-components/native';
import ButtonText from './ButtonText';
import { NormalButtonWidth } from '../Helper/DeviceHelper';
import { TextStyle, ViewStyle } from 'react-native';
import { WaffleDarkGrey, WaffleOrange } from '../Constants/Style';
import { Horizontal } from './SimpleLayouts';
import SpinnerBlack from './SpinnerBlack';
import { Spacer } from './SimpleComponents';

const TouchableRounded = styled.TouchableOpacity`
  height: 55px;
  width: 269px;
  border-radius: 28px;
  justify-content: center;
  background-color: ${WaffleOrange};
`;

//
// WaffleButton is Waffle Orange when active, Waffle Grey when inactive.
//
export interface WaffleButtonAsyncProps {
  onPress: () => Promise<void>;
  disabled?: boolean;
  style?: ViewStyle;
  pressedStyle?: ViewStyle;
  disabledStyle?: ViewStyle;
  textStyle?: TextStyle;
  pressedTextStyle?: TextStyle;
  disabledTextStyle?: TextStyle;
  name: string;
  pressedName?: string;
  testID?: string;
}

export const WaffleButtonAsync = ({
  onPress,
  disabled,
  style,
  pressedStyle,
  textStyle,
  pressedTextStyle,
  disabledTextStyle,
  disabledStyle,
  name,
  pressedName,
  testID,
}: WaffleButtonAsyncProps) => {
  const [pressed, setPressed] = useState(false);

  const doOnPress = async () => {
    if (!disabled && !pressed) {
      await setPressed(true);

      try {
        await onPress();
      } finally {
        setPressed(false);
      }
    }
  };

  return (
    <TouchableRounded
      activeOpacity={0.5}
      style={{
        ...(disabled || pressed
          ? { backgroundColor: WaffleDarkGrey }
          : { backgroundColor: WaffleOrange }),
        width: NormalButtonWidth(),
        ...style,
        ...(pressed ? pressedStyle : {}),
        ...(disabled ? disabledStyle : {}),
      }}
      onPress={doOnPress}>
      <Horizontal>
        <ButtonText
          testID={testID}
          style={{
            ...(disabled || pressed ? { color: '#1E2368' } : { color: '#FFF' }),
            ...(disabled ? { opacity: 0.3 } : { opacity: 1.0 }),
            ...textStyle,
            ...(pressed ? pressedTextStyle : {}),
            ...(disabled ? disabledTextStyle : {}),
          }}>
          {pressed ? pressedName || name : name}
        </ButtonText>
        {pressed && (
          <>
            <Spacer x={1} />
            <SpinnerBlack size={'small'} />
          </>
        )}
      </Horizontal>
    </TouchableRounded>
  );
};
