import React from 'react';
import styled from 'styled-components/native';

import { ActivityIndicator } from './SimpleComponents';
import { WaffleOrange } from '../Constants/Style';

const IndicatorView = styled.View`
  flex-direction: row;
  justify-content: center;
`;

const Spinner = () => (
  <IndicatorView>
    <ActivityIndicator size="large" color={WaffleOrange} />
  </IndicatorView>
);

export default Spinner;
