import { useQuery } from '@apollo/client';
import React from 'react';

import styled from 'styled-components/native';
import WaffleText from '../WaffleText';
import Products from '../../Constants/Products';
import {
  COVERAGE_TRAVEL_CFAR,
  CoverageFilter,
  IsCyberPolicy,
  IsPetPolicy,
  PolicyShortFrequencyLabel,
  TotalPolicyPrice,
  TravelPolicyCFARValue,
} from '../../Helper/PolicyHelper';
import { priceFmt, valToLabel } from '../../Helper/MiscFunctions';
import { Markup, TemplatedMarkup } from '../Markup';
import { PolicyItemsDisplay } from '../../PolicyItemsDisplay';
import { HorizontalSpread, VerticalCenter } from '../SimpleLayouts';
import { PetAgreeDisclosures, CyberAgreeDisclosures } from './AgreeDisclosures';
import { GET_LOCAL_USER } from '../../GraphQL/Waffle/Queries';
import { DataRow } from '../SimpleComponents';
import {
  AdaptiveBiggerContentWidth,
  isFullWeb,
} from '../../Helper/DeviceHelper';
import {
  Policy,
  PolicyCoverage,
  PolicyCoverageCoverage,
  PolicyProps,
  Product,
} from '../../../component-config';
import { LocalUser } from '../../../../operation-result-types';

type DetailProps = {
  policy: Policy;
  product: Product;
  consent: boolean;
  setConsent: (boolean) => void;
};

const ProductImage = styled.Image`
  width: 59px;
  height: 59px;
`;

const DetailText = styled(WaffleText)`
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  text-transform: uppercase;
  /* */
  margin-top: 11px;
`;

const DetailView = styled.View`
  margin: 26px 34px 26px 26px;
`;

const Price = styled(WaffleText)`
  color: #4297e7;
  font-size: 23px;
  font-weight: 500;
  line-height: 32px;
`;

const PerMonth = styled(WaffleText)`
  color: #4297e7;
  font-size: 13px;
  line-height: 18px;
`;

type CoverageDescriptionProps = {
  coverage: PolicyCoverageCoverage;
  v: string;
};

const CoverageDescription = ({ v, coverage }: CoverageDescriptionProps) => {
  // This does the simplest variable substitution, but it should be pretty safe.
  // If we need something more complicated, we should move to a proper sandboxed
  // variable substitution mechanism.
  const {
    data: { user },
  } = useQuery(GET_LOCAL_USER);
  const value = valToLabel(v, coverage.choices);
  const description = coverage?.policyDescription?.replace(
    '{value}',
    value?.toLocaleString()
  );

  return (
    <TemplatedMarkup
      style={{
        paragraph: {
          marginTop: 9,
          marginBottom: 0,
        },
      }}
      template={description || coverage.name}
      context={{ user, value: value?.toLocaleString() }}
    />
  );
};

const TravelCFarDisclosure = ({ policy }: PolicyProps) => {
  if (TravelPolicyCFARValue(policy) === 'true') {
    return (
      <Markup>
        Your Plan includes Cancel for Any Reason Coverage. This Cancel For Any
        Reason Coverage does not cover penalties associated with any air or
        other travel arrangements not provided by the Travel Supplier or the
        failure of the Travel Supplier to provide the bargained for Travel
        Arrangements due to cessation of operations for any reason. Benefits
        apply only for trip payments made with cash, credit card or check.
      </Markup>
    );
  }
  return null;
};

export const PolicyDescription = ({ policy }: PolicyProps) => {
  const {
    data: { user },
  } = useQuery<LocalUser>(GET_LOCAL_USER);
  const template = policy?.product?.policyDescription;
  const inputs = policy?.answerInputs;
  const add_ons = policy.addOns ?? [];

  if (template) {
    return (
      <TemplatedMarkup
        template={template}
        context={{ user, inputs, add_ons }}
      />
    );
  }

  return <></>;
};

type CoverageProps = {
  isPet: boolean;
  policy: Policy;
  coverage: PolicyCoverage;
  idx: React.Key;
};

const Coverage = ({ isPet, policy, coverage, idx }: CoverageProps) => {
  // Logger(`coverage: ${isPet} ${JSON.stringify(coverage)}`)
  if (coverage.productCoverageId === COVERAGE_TRAVEL_CFAR) {
    return null;
  }

  if (isPet && coverage.productCoverageId === 'Level') {
    // For pet, show in-line policy itemization

    return (
      <>
        <CoverageDescription
          key={coverage.id + '' + idx}
          v={coverage.coverageLimit}
          coverage={coverage.coverage}
        />
        <VerticalCenter>
          <PolicyItemsDisplay policy={policy} showTotal={false} />
        </VerticalCenter>
      </>
    );
  } else {
    return (
      <CoverageDescription
        key={coverage.id + '' + idx}
        v={coverage.coverageLimit}
        coverage={coverage.coverage}
      />
    );
  }
};

const OtherDetail = ({ policy, product, consent, setConsent }: DetailProps) => {
  const isPet = IsPetPolicy(policy);
  const isCyber = IsCyberPolicy(policy);
  const frequencyLabel = PolicyShortFrequencyLabel(policy);
  const width = isFullWeb() ? 720 : AdaptiveBiggerContentWidth();

  // We may want to exclude some coverage items. (e.g. Renters if liability-only)
  const coverageFilter = (c: PolicyCoverage) => CoverageFilter(policy, c);

  // Logger(`YourPolicy product: ${JSON.stringify(product, null, 2)}`);

  return (
    <>
      <DataRow
        style={{
          height: 50,
          width,
        }}>
        <HorizontalSpread>
          <DetailText>{product.name} Details</DetailText>

          <PerMonth>
            <Price>${priceFmt(TotalPolicyPrice(policy))}</Price>
            {frequencyLabel}
          </PerMonth>
        </HorizontalSpread>
      </DataRow>

      {policy.coverage
        .filter(coverageFilter)
        .map((c: PolicyCoverage, idx: React.Key) => (
          <Coverage
            key={idx}
            coverage={c}
            idx={idx}
            isPet={isPet}
            policy={policy}
          />
        ))}

      <PolicyDescription policy={policy} />

      <TravelCFarDisclosure policy={policy} />

      {isPet && (
        <PetAgreeDisclosures consent={consent} setConsent={setConsent} />
      )}
      {isCyber && (
        <CyberAgreeDisclosures
          disclosure={product.reviewPolicyDisclosure}
          consent={consent}
          setConsent={setConsent}
        />
      )}
    </>
  );
};

export const PolicyDetail = ({
  policy,
  product,
  consent,
  setConsent,
}: DetailProps) => {
  const { detailImg } = Products[policy.productId];

  return (
    <DetailView>
      <ProductImage source={detailImg} resizeMode={'contain'} />

      <OtherDetail
        policy={policy}
        product={product}
        consent={consent}
        setConsent={setConsent}
      />
    </DetailView>
  );
};
