import React from 'react';
import WaffleTextInput from './WaffleTextInput';
import { WaffleTextInputProps } from '../../component-config';
import { TextInputProps } from 'react-native';
import * as yup from 'yup';

export const EmailTextInputProps: TextInputProps = {
  autoCapitalize: 'none',
  autoCorrect: false,
  autoCompleteType: 'username',
  keyboardType: 'email-address',
  textContentType: 'username', // for iOS should use keychain
};

export const EmailValidator = yup
  .string()
  .required('Email is required')
  .email();

export default ({ otherProps, ...others }: WaffleTextInputProps) => (
  <WaffleTextInput
    placeholder={'Email Address'}
    otherProps={{
      ...otherProps,
      ...EmailTextInputProps,
    }}
    {...others}
  />
);
