import React from 'react';
import styled from 'styled-components/native';
import ButtonText from './ButtonText';
import { WaffleOrange } from '../Constants/Style';
import { NormalButtonWidth } from '../Helper/DeviceHelper';
import { TextStyle, ViewStyle } from 'react-native';

const TouchableRounded = styled.TouchableOpacity`
  height: 55px;
  width: ${NormalButtonWidth()}px;
  border-radius: 28px;
  justify-content: center;
  background-color: ${WaffleOrange};
`;

type WaffleButtonProps = {
  disabled?: boolean;
  style?: ViewStyle;
  onPress: () => void;
  textStyle?: TextStyle;
  name: string;
};

//
// WaffleButton is Waffle Orange when active, Waffle Grey when inactive.
//
export const WaffleButton = (props: WaffleButtonProps) => (
  <TouchableRounded
    activeOpacity={0.5}
    style={{
      ...(props.disabled
        ? { backgroundColor: '#F0F0F0' }
        : { backgroundColor: WaffleOrange }),
      ...props.style,
    }}
    onPress={() => props.disabled || props.onPress()}>
    <ButtonText
      testID={props.disabled ? 'disabledBtn' : 'enabledBtn'}
      style={{
        ...(props.disabled ? { color: '#1E2368' } : { color: '#FFF' }),
        ...(props.disabled ? { opacity: 0.3 } : { opacity: 1.0 }),
        ...props.textStyle,
      }}>
      {props.name}
    </ButtonText>
  </TouchableRounded>
);
