import React, { useEffect, useState } from 'react';
import { GiftedChat, GiftedChatProps } from 'react-native-gifted-chat';
import {
  renderAvatar,
  renderBubble,
  renderComposer,
  renderMessage,
  renderSend,
  WaffleDefaultsChatStyle,
} from './Chat/ChatHelpers';
import { RenderQuickReplies } from './Chat/RenderQuickReplies';
import { EmailTextInputProps, EmailValidator } from './UserNameInput';
import { Spacer } from './SimpleComponents';
import { VerticalCenter } from './SimpleLayouts';
import { ErrorText } from './ErrorText';
import TypingIndicator from './TypingIndicator';
import { Logger } from '../Helper/Logger';
import { cleanNonAscii } from '../Helper/MiscFunctions';
import { useUserTracking } from '../../lib/user-tracking/UserTrackingProvider';

const validate = (message: any, text: string) => {
  if (message?.validators) {
    switch (message.validators) {
      case 'Positive Integer':
        if (
          message.text.startsWith('How many travelers') ||
          message.text.startsWith('How many pets')
        ) {
          // todo : make this more robust!
          return text.match(/^[1-9]?$/);
        }
        return text.match(/^\d*$/);
    }
  }

  return true;
};

const isValid = (message: any, text: string) => {
  switch (message?.validators) {
    case 'Email':
      return EmailValidator.isValidSync(text);

    case 'GreatThanZero':
      return text.length > 0 && parseInt(text) > 0;
  }

  return true;
};

const textInputPropsByMessage = (message: any) => {
  switch (message?.validators) {
    case 'Positive Integer':
      return {
        // autoCompleteType: 'postal-code',
        keyboardType: 'number-pad',
        // textContentType: 'postalCode',
      };

    case 'Email':
      return EmailTextInputProps;
  }

  return {};
};

type WaffleChatProps = {
  userId: string;
  doComplete?: () => Promise<void>;
  onPicker?: (text: string, value: any) => Promise<void>;
};

type State = {
  text: string;
  isTyping?: boolean | undefined;
  error?: string | undefined;
};

const WaffleChat = ({
  userId,
  textInputProps,
  messages,
  onSend,
  ...props
}: WaffleChatProps & GiftedChatProps) => {
  const [state, setState] = useState<State>({
    text: '',
    isTyping: false,
  });
  const userTracking = useUserTracking();
  const lastIndex = messages?.length ? messages.length - 1 : 0;
  const lastMessage = messages?.[lastIndex];

  useEffect(() => {
    Logger(
      `useEffect lastMessage: ${lastMessage?.user?._id} lastIndex=${lastIndex}`
    );

    setState({ text: '', isTyping: lastMessage?.user?._id !== 'waffle' });
  }, [lastIndex]);

  const onChangeText = (textIn: string) => {
    const text = cleanNonAscii(textIn);

    if (validate(lastMessage, text)) {
      setState((existing) => ({ text, isTyping: existing.isTyping }));
    } else {
      setState(state); // effectively ignore this update
    }
  };

  const doSend = async (messages: any) => {
    if (!isValid(lastMessage, messages[0]?.text)) {
      setState({
        text: messages[0]?.text,
        error: 'Please enter a valid email address',
      });
      return;
    }

    await setState((existing) => ({ ...existing, isTyping: true }));

    await onSend(messages);

    // @ts-ignore
    const messageIsFromServer = lastMessage.partyId === 'waffle';
    // @ts-ignore
    const nextMessageIsAnEmail = lastMessage.validators === 'Email';
    if (messageIsFromServer && nextMessageIsAnEmail) {
      userTracking?.updateUserEmail(messages[0]?.text);
    }

    setState((existing) => ({ text: '', isTyping: existing.isTyping }));
  };

  const renderAccessory = (props) => (
    <VerticalCenter style={{ width: '100%' }}>
      <Spacer y={1} />
      <ErrorText>{state.error}</ErrorText>
    </VerticalCenter>
  );

  const renderFooter = () => (
    <TypingIndicator isTyping={state.isTyping || false} />
  );

  return (
    <GiftedChat
      {...WaffleDefaultsChatStyle} // ??
      keyboardShouldPersistTaps={'always'}
      messages={messages}
      renderAccessory={renderAccessory}
      renderAvatar={renderAvatar}
      renderBubble={renderBubble}
      renderComposer={renderComposer}
      renderFooter={renderFooter}
      renderMessage={renderMessage}
      renderQuickReplies={RenderQuickReplies}
      renderSend={renderSend}
      renderTime={() => null}
      renderDay={() => null}
      isTyping={state.isTyping}
      user={{ _id: userId }}
      onSend={doSend}
      text={state.text}
      onInputTextChanged={onChangeText}
      textInputProps={{
        autoFocus: true,
        ...textInputPropsByMessage(lastMessage),
        ...textInputProps,
        textID: 'chat-input-text-field',
      }}
      {...props}
    />
  );
};

export default WaffleChat;
