import React from 'react';
import styled from 'styled-components/native';
import WaffleText from './WaffleText';
import { PlainView, TextInput } from './SimpleComponents';
import { HorizontalSpread } from './SimpleLayouts';
import { WaffleTextInputProps } from '../../component-config';

const BaseText = styled(WaffleText)`
  height: 20px;
  font-size: 14px;
  line-height: 20px;
`;

const Text = styled(BaseText)`
  text-transform: uppercase;
  text-align: left;
`;

const FooterText = styled(Text)`
  height: 16px;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;
  text-align: left;
`;

const ErrorFooterText = styled(FooterText)`
  color: red;
  text-transform: none;
`;

const WaffleTextInput = ({
  containerProps,
  containerStyle,
  label,
  placeholder,
  placeholderTextColor,
  otherProps,
  textInputStyle,
  onChangeText,
  value,
  footerText,
  errorFooterText,
  onBlur,
  onValidate,
  optional = false,
  ref,
}: WaffleTextInputProps) => (
  <PlainView {...containerProps} style={containerStyle}>
    <HorizontalSpread>
      <Text>{label}</Text>
      {optional && <BaseText>(Optional)</BaseText>}
    </HorizontalSpread>
    <TextInput
      {...otherProps}
      ref={ref}
      placeholder={placeholder}
      placeholderTextColor={placeholderTextColor || '#AFAFAF'}
      style={textInputStyle}
      onChangeText={onChangeText}
      onEndEditing={(ev) => onValidate && onValidate(ev.nativeEvent.text)}
      onBlur={onBlur}
      value={value}
    />
    {!!footerText && <FooterText>{footerText}</FooterText>}
    {!!errorFooterText && <ErrorFooterText>{errorFooterText}</ErrorFooterText>}
  </PlainView>
);

export default WaffleTextInput;
