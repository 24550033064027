import React from 'react';
import { Button, Platform } from 'react-native';
import { TextLightLink } from './SimpleComponents';
import CustomLinking from '../Helper/CustomLinking';
import { useNavigation } from '@react-navigation/native';
import { SimpleWebScreenName } from '../../screen-config';

// TODO: change to object api b/c there are too many params
export const ShowWebLink = async (
  navigation: any,
  uri: any,
  title = null,
  loadingText = null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onDismiss = async () => {}
) => {
  if (Platform.OS === 'web') {
    await CustomLinking.openURL(uri, '_blank');
    if (onDismiss) await onDismiss(); // xxx : hm...
  } else {
    await navigation.navigate(SimpleWebScreenName, {
      uri,
      title,
      loadingText,
      headerRight: () => (
        <Button
          onPress={async () => {
            if (onDismiss) await onDismiss();
            navigation.goBack();
          }}
          title="Done"
          color="#000"
        />
      ),
    });
  }
};

export const WebViewPopupLink = ({
  text,
  uri,
  title,
  loadingText,
  onDismiss,
}: any) => {
  const navigation = useNavigation();

  return (
    <TextLightLink
      onPress={async () =>
        await ShowWebLink(navigation, uri, title, loadingText, onDismiss)
      }>
      {text}
    </TextLightLink>
  );
};
