import React, { useState } from 'react';
import {
  WaffleTextIconInputProps,
  WaffleTextInputProps,
} from '../../component-config';
import { Platform } from 'react-native';
import styled from 'styled-components/native';
import { Input } from 'react-native-elements';
import { White } from '../Constants/Style';

const View = styled.View`
  flex: 1;
`;

// excluded props:
// footerText, label, optional
export const TextIconInput = ({
  containerProps,
  containerStyle,
  onValidate,
  placeholder,
  errorFooterText,
  leftIcon,
  ref,
  onChangeText,
  otherProps,
  onFocus,
  onBlur,
  value,
}: WaffleTextInputProps & WaffleTextIconInputProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <View {...containerProps} style={containerStyle}>
      <Input
        ref={ref}
        {...otherProps}
        placeholder={placeholder}
        placeholderTextColor={'#CDCFD0'}
        onFocus={(e) => {
          onFocus && onFocus(e);
          setIsFocused(true);
        }}
        onBlur={(e) => {
          onBlur && onBlur(e);
          setIsFocused(false);
        }}
        leftIcon={leftIcon}
        inputStyle={{
          minWidth: 175,
          // To turn off the border around the text input on web, we need to do this.
          ...Platform.select({
            web: {
              outline: 'none',
            },
          }),
        }}
        inputContainerStyle={{
          borderRadius: 8,
          borderWidth: 1,
          paddingLeft: 8,
          backgroundColor: isFocused ? White : '#F2F4F5',
          borderColor: isFocused ? '#E3E5E6' : '#F2F4F5',
        }}
        containerStyle={{
          marginLeft: 0,
          marginRight: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
        errorMessage={errorFooterText}
        onChangeText={onChangeText}
        onEndEditing={(ev) => onValidate && onValidate(ev.nativeEvent.text)}
        value={value}
      />
    </View>
  );
};
