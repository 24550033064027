import React, { useEffect, useRef, useState } from 'react';
import { Logger } from '../../Helper/Logger';
import { VisualViewportProps } from './VisualViewport';
import { View } from 'react-native';
import { useHeaderHeight } from '@react-navigation/stack';
import { isFullWeb } from '../../Helper/DeviceHelper';

type State = {
  height: string | number;
  width: string | number;
};

// adapted from a gist wraps the web Viewport API
export const VisualViewport = ({
  children,
  style = {},
}: VisualViewportProps) => {
  const ref = useRef(null);
  const headerHeight = useHeaderHeight();

  const [viewport, setViewport] = useState<State>({
    height: '100%',
    width: '100%',
  });

  const updateViewport = () => {
    if (window.visualViewport.height && window.visualViewport.width) {
      setViewport({
        height: window.visualViewport.height - headerHeight - 10,
        width: window.visualViewport.width,
      });
    }

    Logger(
      `viewport: h=${JSON.stringify(
        window.visualViewport.height
      )} w=${JSON.stringify(
        window.visualViewport.width
      )} hh=${headerHeight} ot=${ref.current.offsetTop}`
    );

    window.scrollTo(0, ref.current.offsetTop);
  };

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      typeof window.visualViewport !== 'undefined'
    ) {
      updateViewport();

      window.visualViewport.addEventListener('resize', updateViewport);

      return () =>
        window.visualViewport.removeEventListener('resize', updateViewport);
    }
  }, []);

  if (isFullWeb()) {
    return (
      <View ref={(r) => (ref.current = r)} style={style}>
        {children}
      </View>
    );
  }

  return (
    <View ref={(r) => (ref.current = r)} style={{ ...style, ...viewport }}>
      {children}
    </View>
  );
};
