import styled from 'styled-components/native';

export const WaffleTextFamily = 'Circular Std';

const WaffleText = styled.Text`
  font-family: ${WaffleTextFamily};
  color: #000000;
`;

export const LabelUpperText = styled(WaffleText)`
  height: 20px;
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 3px;
`;

export const BoldText = styled(WaffleText)`
  font-weight: 500;
`;

export const WaffleHeaderText = styled(WaffleText)`
  font-size: 28px;
`;

export const MediumText = styled(WaffleText)`
  font-size: 15px;
  line-height: 18px;
`;

export const LargeText = styled(WaffleText)`
  font-size: 20px;
  line-height: 23px;
`;

export const LargeBoldText = styled(LargeText)`
  font-weight: bold;
`;

export const BigText = styled(WaffleText)`
  font-size: 30px;
  font-weight: 300;
  line-height: 34px;
`;

export const BigBoldText = styled(BigText)`
  font-weight: 600;
`;

export const PopupTitle = styled(WaffleText)`
  font-size: 24px;
  font-weight: 500;
  line-height: 39px;
  text-align: center;
`;

export default WaffleText;
